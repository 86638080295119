document.addEventListener('turbolinks:load', () => {
  if ($('[data-formatted-date=false]').length) {
    $('[data-formatted-date=false]').each((i, dateObject) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date($(dateObject).text());
      const formattedDate = date.toLocaleDateString(undefined, options);
      $(dateObject).text(formattedDate);
      $(dateObject).attr('data-formatted-date', true);
    });
  }
});
