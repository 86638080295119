// eslint-disable-next-line no-param-reassign
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  static classes = ['toggle'];

  toggle(event) {
    const elementToToggle = event.target.closest("[data-class-toggle-target='item']");
    const allElements = this.itemTargets;
    const elementCurrentlyToggled = allElements.find((e) => e.dataset.classToggleTarget.includes('current'));

    if (elementToToggle === elementCurrentlyToggled) return;

    allElements.forEach((e) => {
      e.dataset.classToggleTarget = 'item';
    });
    elementToToggle.dataset.classToggleTarget = 'item current';

    allElements.forEach((e) => {
      this.toggleClasses.forEach((className) => {
        if (e === elementToToggle) {
          e.classList.add(className);
        } else {
          e.classList.remove(className);
        }
      });
    });
  }
}
