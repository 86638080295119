import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];

  enable() {
    this.itemTargets.forEach((item) => {
      item.removeAttribute('disabled');
      item.classList.remove('cursor-not-allowed');
    });
  }

  disable() {
    this.itemTargets.forEach((item) => {
      item.addAttribute('disabled');
      item.classList.add('cursor-not-allowed');
    });
  }
}
